<template>
  <div>
    <div class="addDialogTrigger z-depth-0"
      @click="openTeamModal">
      <span class="el-icon-plus"></span>
    </div>
    <section>
      <div class="container">
        <div class="team_dialogs">
          <el-dialog title="Add Gallery Category"
            :visible.sync="addGalleryCategoryModal"
            :destroy-on-close="true"
            :before-close="handleClose">
            <div v-loading="submitting">
              <el-form :model="galleryCategoryForm"
                label-position="top"
                :rules="teamRules"
                ref="galleryCategoryForm"
                label-width="120px"
                class="demo-galleryCategoryForm"
                onSubmit="return false;">
                <div class="row mb-2">
                  <div class="col-md-12">
                    <el-form-item label="Name"
                      prop="name">
                      <el-input v-model="galleryCategoryForm.name"
                        @keyup.enter.native="addGalleryCategory('galleryCategoryForm')"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-2 mt-3"
                      style="font-weight: 500; color: rgba(0, 0, 0, 0.8)">
                      Category Picture
                    </div>

                    <div v-if="galleryCategoryImage"
                      class="position-relative category-photo"
                      style="background: #fdfdfd">
                      <img :src="galleryCategoryImage"
                        style="width: 100%; height: 100%; object-fit: cover;">
                      <div class="remove-img w-100 d-flex align-items-center justify-content-center"
                        style="height: 100%;">
                        <el-button class="remove-img-btn"
                          icon="el-icon-delete"
                          style="font-size: 1.5rem; color: #fff; background-color: rgba(0, 0, 0, 0.45)"
                          @click="handleRemove">
                        </el-button>
                      </div>
                    </div>

                    <div v-else>
                      <input id="student-photo-file-input"
                        type="file"
                        class="d-none"
                        accept=".jpg,.png,.jpeg"
                        @change="onImageChange"
                        @click="onImageInputClick" />
                      <label for="student-photo-file-input"
                        class="add-img">
                        <div class="w-100 d-flex align-items-center justify-content-center"
                          style="height: 200px;">
                          <span class="el-icon-plus"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <el-form-item class="text-center">
                  <el-button type="primary"
                    @click="addGalleryCategory('galleryCategoryForm')">Add Gallery Category</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-dialog>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      addGalleryCategoryModal: false,
      submitting: false,
      galleryCategoryImage: "",
      selectedFile: null,
      isUploadingFile: false,
      galleryCategoryForm: {
        name: "",
      },
      teamRules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    openTeamModal() {
      this.addGalleryCategoryModal = true;
    },

    // Do this to make sure change event for the input fires even after choosing same file
    onImageInputClick(e) {
      e.target.value = "";
    },
    onImageChange(e) {
      e.preventDefault();
      const ALLOWED_FILE_EXTENSIONS = ["png", "jpg", "jpeg"];

      // Ensure the file type chosen is an image in the allowed file extensions
      this.selectedFile = e.target.files[0];
      const fileExtension = this.selectedFile.name
        ?.split(".")
        ?.pop()
        ?.toLowerCase();
      if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
        return this.showWarningMessage(
          "Invalid file-Type",
          `Only images of type ${ALLOWED_FILE_EXTENSIONS.toString()} are allowed`
        );
      }
      // Otherwise set the image url to show on the UI
      this.galleryCategoryImage = URL.createObjectURL(this.selectedFile);
    },
    handleRemove() {
      this.galleryCategoryImage = "";
      this.selectedFile = null;
    },

    handleClose(done) {
      this.clearInputs();
      done();
    },
    clearInputs() {
      this.galleryCategoryImage = "";
      this.selectedFile = null;
      this.galleryCategoryForm.name = "";
    },
    async addGalleryCategory(formName) {
      if (this.selectedFile == null) {
        this.isUploadingFile = false;
        return this.showWarningMessage(
          "Photo Required",
          "Gallery Photo is Required"
        );
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {

          //Ensure the file is not more than 2MB
          if (this.selectedFile.size > 2000000) {
            this.isUploadingFile = false;
            return this.showWarningMessage(
              "File Too Large",
              "The file size should not be more than 2MB"
            );
          }

          this.submitting = true;
          try {
            const awsUploadUrl = await this.imageUploadUrl();
            const imagePathUrl = await this.uploadPhotoToAWS(
              awsUploadUrl,
              this.selectedFile
            );

            const response = await this.$http.post(
              `website/gallery/categories`,
              {
                name: this.galleryCategoryForm.name,
                image_path_url: imagePathUrl
              }
            );
            if (
              response.data.success == true &&
              response.data.message == "CATEGORY ADDED SUCCESSFULLY"
            ) {
              this.$emit("on-refresh");
              this.addGalleryCategoryModal = false;
              this.submitting = false;
              this.clearInputs();
              return this.showSuccessMessage(
                "Added Successfully",
                "The Category name was successfully created."
              );
            } else {
              throw "UNEXPECTED API RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.showFailedMessage(
                "Connection failed",
                "A network error occurred, please try again."
              );
            } else if (error.response) {
              if (
                error.response.data.message == "CATEGORY NAME ALREADY IN USE"
              ) {
                return this.showWarningMessage(
                  "Duplicate Entry",
                  "The provided category name is already in use"
                );
              }
            } else {
              return this.showFailedMessage(
                "Unexpected Error",
                "An unexpected error occurred. Please try again"
              );
            }
            this.showFailedMessage(
              "Operation Failed",
              "Unable to Add Gallery Category"
            );
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // uploading
    async imageUploadUrl() {
      this.isUploadingFile = true;
      try {
        this.isUploadingFile = true;
        let request = await this.$http.post(
          "website/request-for-file-upload-url",
          {
            file_name: this.selectedFile.name,
            file_size: this.selectedFile.size,
            reason: "GALLERY",
          }
        );
        if (
          request.data.success &&
          request.data.message == "UPLOAD URL GENERATED SUCCESSFULLY"
        ) {
          return request.data.upload_url;
        } else if (request.data.message == "UNSUPPORTED_FILE_TYPE") {
          return this.showWarningMessage(
            "Unsupported file",
            "The file you are trying to upload is not supported"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "NetworkError") {
          this.isUploadingFile = false;
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        this.isUploadingFile = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload the Photo now, Please try again"
        );
      }
    },

    async uploadPhotoToAWS(aws_upload_url, selected_file) {
      this.isUploadingFile = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.isUploadingFile = true;

        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
          onUploadProgress: (progressEvent) => console.log(progressEvent),
        });

        if (request.status == 204) {
          return request.headers.location;
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "Network Error") {
          this.isUploadingFile = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isUploadingFile = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Photo now, Please try again"
        );
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.addDialogTrigger {
  border: 1px dashed black;
  border-radius: 5px;
  height: 100%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-img {
  display: none;
}

.remove-img-btn {
  display: none;
}

.category-photo:hover .remove-img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.45);
}

.category-photo:hover .remove-img-btn {
  display: block;
}

.add-img {
  border: 1px dashed rgb(189, 187, 187);
  border-radius: 5px;
  height: 80%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.team_dialogs .el-dialog {
  width: 40%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .team_dialogs .el-dialog {
    width: 50%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .team_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
</style>